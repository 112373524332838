<template>
  <div id="container">
    <div :class="'poster ' + (posterShow ? 'pop-show' : 'pop-hide')">
      <img :src="poster" />
      <!-- <div class="loading"></div> -->
      <div class="progress">
        <div class="current-num">{{ "加载进度：" + loadingProgress }}</div>
        <div class="current" :style="'width:' + loadingProgress"></div>
      </div>
    </div>
  </div>
</template>
<script lang="js">
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
// import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader"
import { judgeMobile } from "@/assets/js/utils"
export default {
  name: "VueThree",
  data() {
    return {
      scene: "",
      light: "",
      camera: "",
      controls: "",
      renderer: "",
      poster: judgeMobile().mobile?`${process.env.VUE_APP_DEPLOY_URL+'/castle/'}mobilebg.png`:`${process.env.VUE_APP_DEPLOY_URL+'/castle/'}pcbg.png`,
      posterShow: true,
      loadingProgress: '0%',
    };
  },
  methods: {
    // 初始化three.js相关内容
    init() {
      const that = this
      // 61资源预加载
      // for(let i = 1; i < 62; i++){
      //   let img = new Image();
      //   let index = i < 10 ? ('0'+i):i;
      //   img.src = `${process.env.VUE_APP_DEPLOY_URL+'/castle/'}20220316/zsahxsg_${index}.jpg`;
      //   img.onload = ()=>{
      //     // console.log(`${index} 加载完毕`)
      //   }
      // }
      // console.log(THREE, "THREE");
      const container = document.getElementById("container");
      // container.style = `background: url("https://cdn.jimuyida.com/jmyd/user/20200913/981fb0e5-20d0-4b5b-aee5-c57b864769c8.jpg");`;
      if(judgeMobile().mobile){
        container.style = `background: url(${process.env.VUE_APP_DEPLOY_URL+'/castle/'}final/castle_bg_mobile.jpg);background-size: 100% 100%;`;
      }else{
        container.style = `background: url(${process.env.VUE_APP_DEPLOY_URL+'/castle/'}final/castle_bg.png);background-position-y: bottom;`;
      }
      // 初始化相机
      const camera = new THREE.PerspectiveCamera( 45, window.innerWidth / window.innerHeight, 1, 10000 );
      camera.position.set( 0, 0, 0 );
      const scene = new THREE.Scene();
      scene.add(new THREE.AmbientLight(0xffffff, 0.8)); //环境光
      // var light = new THREE.HemisphereLight( 0xffffff, 0xffffff, 1 );
      // scene.add( light );
      // let directionalLight = new THREE.DirectionalLight( 0xffffff, 3 ); //平行光
      // if(judgeMobile().mobile){
      //   directionalLight = new THREE.DirectionalLight( 0xffffff, 1 ); //平行光
      // }
      // directionalLight.position.set(0, 1000, 10000)
      // directionalLight.target.position.set(0, 0, 0)
      // scene.add( directionalLight );
      // scene.add( directionalLight.target );
      // var light = new THREE.PointLight( 0xffffff, 1 );
      // light.position.set( 0, 0, 0 );
      // scene.add( light );
      // console.log(scene, 'scene');

      let raycaster = new THREE.Raycaster();
      let mouse = new THREE.Vector2();
      const clickArray = ['zsahxsg_72', 'zsahxsg_69', 'zsahxsgtga_06'];
      const onMouseClick = (event)=>{
        // console.log(event, 'onMouseClick');
        // 将鼠标位置归一化为设备坐标。x 和 y 方向的取值范围是 (-1 to +1)
        mouse.x = ( event.clientX / window.innerWidth ) * 2 - 1;
        mouse.y = - ( event.clientY / window.innerHeight ) * 2 + 1;
        // 通过鼠标点的位置和当前相机的矩阵计算出raycaster
        raycaster.setFromCamera( mouse, camera );
        // 获取raycaster直线和所有模型相交的数组集合
        let intersects = raycaster.intersectObjects( scene.children );
        // console.log(intersects, "intersects");
        for(let i of intersects){
          // console.log(i.object.name, 'i.object.name')
          // if(i && i.object && i.object.name && i.object.name == "zsahxsg_02"){
          if(i && i.object && i.object.name && clickArray.includes(i.object.name)){
            that.$router.push({ name: 'index' });
            break;
          }
        }
      }
      const onTouchStart = (event)=>{
        // 将鼠标位置归一化为设备坐标。x 和 y 方向的取值范围是 (-1 to +1)
        mouse.x = ( event.targetTouches[0].clientX / window.innerWidth ) * 2 - 1;
        mouse.y = - ( event.targetTouches[0].clientY / window.innerHeight ) * 2 + 1;
        // 通过鼠标点的位置和当前相机的矩阵计算出raycaster
        raycaster.setFromCamera( mouse, camera );
        // 获取raycaster直线和所有模型相交的数组集合
        let intersects = raycaster.intersectObjects( scene.children );
        // console.log(intersects, "intersects");
        for(let i of intersects){
          if(i && i.object && i.object.name && clickArray.includes(i.object.name)){
            that.$router.push({ name: 'index' });
            break;
          }
        }
      }
      const render = ()=>{
        renderer.render(scene, camera);
      }
      if(judgeMobile().mobile){
        window.addEventListener( 'touchstart', onTouchStart, false );
      }else{
        window.addEventListener( 'click', onMouseClick, false );
      }
      let mixer, mixer2;
      let clock = new THREE.Clock();
      let animate = function () {
          requestAnimationFrame( animate );
          let time = clock.getDelta();
          // console.log(time, 'time');
          if (mixer) {
              mixer.update(time);
          }
          if (mixer2) {
              mixer2.update(time);
          }
          controls.update();
          render();
      };
      // new RGBELoader()
      // .load( `${process.env.VUE_APP_DEPLOY_URL+'/castle/'}final/castle_bg.hdr`, function ( texture ) {
        // console.log(texture, 'texture');
        // texture.mapping = THREE.EquirectangularReflectionMapping;
        // scene.background = texture;
        // scene.environment = texture;
        // render();
        let dracoLoader = new DRACOLoader();
        let loader = new GLTFLoader();
        dracoLoader.setDecoderPath(`${process.env.VUE_APP_DEPLOY_URL+'/castle/'}final/draco/`); // 设置public下的解码路径，注意最后面的/
        dracoLoader.setDecoderConfig({ type: "js" });
        dracoLoader.preload();
        loader.setDRACOLoader(dracoLoader);
        loader.load( `${process.env.VUE_APP_DEPLOY_URL+'/castle/'}final/gg.gltf`, function ( gltf ) {
            // console.log(gltf, 'gltf');
            // console.log(gltf.scene, 'gltf.scene');
            // gltf.scene.rotation.set(0, 3.15, 0)
            mixer = new THREE.AnimationMixer( gltf.scene );
            // gltf.scene.scale.x = 100;
            // gltf.scene.scale.y = 100;
            // gltf.scene.scale.z = 100;
            gltf.scene.position.y = 100;
            mixer.addEventListener( 'loop', function(  ) {
              // console.log(e, 'loop');
            } );
            mixer.addEventListener( 'finished', function(  ) {
              // console.log(e, 'finished');
            } );
            //同时将这个外部模型的动画全部绑定到动画混合器里面
            // console.log(gltf, 'gltf');
            gltf.animations.map(item=>{
              // console.log(item, 'item');
              item.duration = item.duration/10;
              let animation = mixer.clipAction(item);
              // animation.timeScale = 3;
              animation.setLoop(THREE.LoopRepeat, Infinity)
              animation.play();
              // console.log(animation, 'animation');
            })
            scene.add( gltf.scene );
            animate();
            let loader2 = new GLTFLoader();
            let dracoLoader2 = new DRACOLoader();
            dracoLoader2.setDecoderPath(`${process.env.VUE_APP_DEPLOY_URL+'/castle/'}final/draco/`); // 设置public下的解码路径，注意最后面的/
            dracoLoader2.setDecoderConfig({ type: "js" });
            dracoLoader2.preload();
            loader2.setDRACOLoader(dracoLoader2);
            loader2.load( `${process.env.VUE_APP_DEPLOY_URL+'/castle/'}final/ee2.gltf`, function ( gltf ) {
                // console.log(gltf.scene, 'gltf.scene2');
                gltf.scene.position.y = 100;
                mixer2 = new THREE.AnimationMixer( gltf.scene );
                // console.log('=================================');
                gltf.animations.map(item=>{
                  // console.log(item, 'item');
                  item.duration = item.duration/10;
                  let animation = mixer2.clipAction(item);
                  // animation.timeScale = 3;
                  animation.setLoop(THREE.LoopRepeat, Infinity)
                  animation.play();
                })
                scene.add( gltf.scene );
                animate();
              });
          },
          function ( xhr ) {
            const number = (xhr.loaded / xhr.total * 100);
            // console.log( '模型加载' + number + '% loaded' );
            if(number < 0){
              that.loadingProgress = `0%`
            }else{
              that.loadingProgress = `${number.toFixed(2)}%`
            }
            // console.log(number == 100, 'number == 100');
            if(number == 100){
              setTimeout(() => {
                that.posterShow = false;
              }, 1000);
            }
          }, );
      // });
      // 渲染
      const renderer = new THREE.WebGLRenderer({
        alpha: true,
      });
      renderer.setPixelRatio( window.devicePixelRatio );
      renderer.setSize( window.innerWidth, window.innerHeight );
      renderer.toneMapping = THREE.ACESFilmicToneMapping;
      renderer.toneMappingExposure = 1;
      renderer.outputEncoding = THREE.sRGBEncoding;
      container.appendChild(renderer.domElement);

      var controls = new OrbitControls( camera, renderer.domElement );
      controls.addEventListener( 'change', render );
      if(judgeMobile().mobile){
        controls.minDistance = 7000;
        controls.maxDistance = 9000;
      }else {
        controls.minDistance = 4500;
        controls.maxDistance = 5500;
      }
      controls.enablePan = false;
      controls.target.set( 0, 0, 1 );
      controls.maxPolarAngle = 1.5;
      controls.update();
      const onWindowResize = ()=>{
        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();
        renderer.setSize( window.innerWidth, window.innerHeight );
        render();
      };
      window.addEventListener( 'resize', onWindowResize );
      let minDistanceControl = 1500;
      if(judgeMobile().mobile){
        minDistanceControl = 3000;
      }
      setTimeout(() => {
        controls.minDistance = minDistanceControl;
        controls.update();
      }, 2000);
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style scoped lang="scss">
.poster {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  img {
    width: 100%;
  }
  @-moz-keyframes rotate {
    0% {
      -moz-transform: rotate(0deg);
    }
    100% {
      -moz-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes rotate {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .loading {
    position: absolute;
    bottom: 10%;
    width: px2rem(128px);
    height: px2rem(128px);
    left: 50%;
    margin-left: px2rem(-64px);
    background: url("../../assets/castle/loading.png");
    background-size: 100% 100%;
    -moz-animation: rotate 2s infinite linear;
    -webkit-animation: rotate 2s infinite linear;
    animation: rotate 2s infinite linear;
  }
  .progress {
    width: px2rem(500px);
    left: 50%;
    margin-left: px2rem(-250px);
    height: px2rem(18px);
    position: fixed;
    z-index: 5;
    bottom: 10%;
    background: #666666;
    // border-radius: px2rem(20px);
    .current {
      width: 0%;
      height: 100%;
      background: #3cab7e;
    }
    .current-num {
      width: 100%;
      position: absolute;
      top: px2rem(-50px);
      text-align: center;
      color: #333333;
      font-size: px2rem(28px);
    }
  }
}
.pop-show {
  display: block;
}
.pop-hide {
  display: none;
}
</style>
